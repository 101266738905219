<template>
  <div class="list-warranty-benefit-policy-page">
    <KTCodePreview v-bind:title="'Danh sách Form đặt trước'">
      <template v-slot:toolbar>
        <div class="row">
          <div class="ml-3">
            <b-dropdown
              size="sm"
              right
              split
              variant="primary"
              @click="redirectToCreatePage()"
              class="ml-4"
              v-if="checkPermission('PRE_ORDER_FORM_CREATE')"
            >
              <template v-slot:button-content>
                <div class="font-weight-bolder">
                  <span> <i class="flaticon2-add-1"></i>Tạo mới</span>
                </div>
              </template>
            </b-dropdown>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <!-- Header session -->
        <!-- tag-->
        <b-row class="mb-5">
          <b-col md="3">
            <b-form-input
              v-model.trim="params.formName"
              type="text"
              placeholder="Tìm kiếm theo tênh form đặt trước"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>

          <b-col md="3">
            <b-form-select
              size="sm"
              v-model="params.type"
              :options="preOrderFormType"
              value-field="id"
              text-field="name"
              class="select-style"
            >
              <template v-slot:first>
                <b-form-select-option :value="null"
                  >-- Chọn loại form đặt trước --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-col>
        </b-row>
        <!--end tag-->
        <b-row class="mb-5">
          <b-col md="1">
            <b-button
              variant="primary"
              size="sm"
              class="btn font-weight-bold btn-size"
              @click="onFilter"
              :disabled="onLoading"
              >Lọc</b-button
            >
          </b-col>
        </b-row>
        <!-- End of Header session -->
        <!-- Table session -->
        <table class="table col-md-12 table-bordered border-top-color">
          <thead>
            <tr>
              <th class="text-center header-table-color" style="width: 5%">
                ID
              </th>
              <th class="text-center header-table-color" style="width: 20%">
                Tên chương trình
              </th>
              <th class="text-center header-table-color">Sản phẩm</th>
              <th class="text-center header-table-color" style="width: 7%">
                SL cho phép
              </th>
              <th class="text-center header-table-color" style="width: 7%">
                SL hiện tại
              </th>
              <th class="text-center header-table-color" style="width: 7%">
                SL đã cọc
              </th>
              <th class="text-center header-table-color" style="width: 12%">
                Loại
              </th>
              <th class="text-center header-table-color" style="width: 10%">
                Trạng thái
              </th>
              <th class="text-center header-table-color" style="width: 5%"></th>
            </tr>
          </thead>
          <tbody v-if="!onLoading" class="table-body">
            <template v-for="preOrderForm in preOrderFormComputed">
              <tr
                v-if="preOrderForm.preOrderFormDetails.length === 0"
                :key="'empty-' + preOrderForm.id"
              >
                <td class="text-center">
                  {{ preOrderForm.id }}
                </td>
                <td class="text-left">
                  {{ preOrderForm.name }}
                </td>
                <td :colspan="4" class="text-center"></td>
                <td class="text-left">
                  <span
                    class="font-weight-bold"
                    v-b-tooltip.hover.right="preOrderForm.typeName"
                    >{{ getTypeName(preOrderForm.type) }}</span
                  >
                </td>
                <td class="text-center">
                  <span
                    v-text="getStatusName(preOrderForm.status)"
                    class="label font-weight-bold label-lg label-inline"
                    :class="statusNameClasses(preOrderForm.status)"
                    style="width: max-content"
                  ></span>
                </td>
                <td class="text-center">
                  <b-dropdown size="sm" no-caret right lazy>
                    <template slot="button-content">
                      <i
                        style="font-size: 1rem"
                        class="flaticon2-settings pr-0"
                      ></i>
                    </template>
                    <b-dropdown-item @click="editItem(preOrderForm)">
                      <span style="color: #3f4254; font-size: 12px">
                        <i style="font-size: 1rem" class="flaticon2-pen"></i>
                        &nbsp; Chỉnh sửa
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
              <tr
                v-else
                v-for="(
                  preOrderFormDetail, index
                ) in preOrderForm.preOrderFormDetails"
                :key="preOrderFormDetail.id"
                :class="'tr-' + preOrderForm.id"
                @mouseover="highlightRow(preOrderForm.id)"
                @mouseleave="removeHighlightRow(preOrderForm.id)"
              >
                <td
                  class="text-center"
                  v-if="index === 0"
                  :rowspan="preOrderForm.preOrderFormDetails.length"
                >
                  {{ preOrderForm.id }}
                </td>
                <td
                  class="text-left"
                  v-if="index === 0"
                  :rowspan="preOrderForm.preOrderFormDetails.length"
                >
                  {{ preOrderForm.name }}
                </td>
                <td>
                  <span v-b-tooltip.hover.right="'tên sản phẩm'" class="pr-1">{{
                    preOrderFormDetail.productName
                  }}</span>
                </td>
                <td class="text-right">
                  <template>
                    <span
                      class="input-style text-right font-weight-bold"
                      v-b-tooltip.hover.right="'Số lượng cho phép cọc'"
                    >
                      {{ preOrderFormDetail.limit }}
                    </span>
                  </template>
                </td>
                <td class="text-right">
                  <span
                    class="text-right font-weight-bold text-primary pre-order-hover"
                    v-b-tooltip.hover.right="
                      'Số lượng đang đặt hàng thực tế(bao gồm đơn đã cọc)'
                    "
                    @click="
                      preOrderFormDetail.current &&
                        redirectToPreOrderPage({
                          formId: preOrderForm.id,
                          productSearch: preOrderFormDetail.productName,
                          status: [
                            PRE_ORDER_STATUS.DEPOSIT,
                            PRE_ORDER_STATUS.NEW,
                          ],
                        })
                    "
                    type="button"
                  >
                    {{ preOrderFormDetail.current }}
                  </span>
                  <span
                    v-if="preOrderFormDetail.preOrderSpamCount"
                    class="font-weight-bold text-danger pl-2"
                    v-b-tooltip.hover.right="'Số lượng đơn spam tạm tính'"
                    >+ {{ preOrderFormDetail.preOrderSpamCount }}</span
                  >
                </td>
                <td class="text-right">
                  <span
                    class="text-primary text-right font-weight-bold pre-order-hover"
                    v-b-tooltip.hover.right="'Số lượng đã cọc hiện tại'"
                    @click="
                      preOrderFormDetail.currentDeposit &&
                        redirectToPreOrderPage({
                          formId: preOrderForm.id,
                          productSearch: preOrderFormDetail.productName,
                          status: PRE_ORDER_STATUS.DEPOSIT,
                        })
                    "
                    type="button"
                  >
                    {{ preOrderFormDetail.currentDeposit }}
                  </span>
                </td>
                <td
                  v-if="index === 0"
                  :rowspan="preOrderForm.preOrderFormDetails.length"
                >
                  <span
                    class="font-weight-bold"
                    v-b-tooltip.hover.right="preOrderForm.typeName"
                    >{{ getTypeName(preOrderForm.type) }}</span
                  >
                </td>
                <td
                  v-if="index === 0"
                  :rowspan="preOrderForm.preOrderFormDetails.length"
                  class="text-center"
                >
                  <span
                    v-text="getStatusName(preOrderForm.status)"
                    class="label font-weight-bold label-lg label-inline"
                    :class="statusNameClasses(preOrderForm.status)"
                    style="width: max-content"
                  ></span>
                </td>
                <td
                  v-if="index === 0"
                  :rowspan="preOrderForm.preOrderFormDetails.length"
                >
                  <b-dropdown size="sm" no-caret right lazy>
                    <template slot="button-content">
                      <i
                        style="font-size: 1rem"
                        class="flaticon2-settings pr-0"
                      ></i>
                    </template>
                    <b-dropdown-item @click="editItem(preOrderForm)">
                      <span style="color: #3f4254; font-size: 12px">
                        <i style="font-size: 1rem" class="flaticon2-pen"></i>
                        &nbsp; Chỉnh sửa
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </template>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="8">
                <template>
                  <vcl-table
                    :speed="5"
                    :animate="true"
                    :columns="8"
                  ></vcl-table>
                </template>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- End of Table session -->

        <!-- Paginate session -->
        <b-row>
          <b-col>
            <p class="mt-3 text-dark font-weight-bold">
              Hiển thị {{ startIndex }} - {{ endIndex }} trong số
              {{ totalItems }} bản ghi
            </p>
          </b-col>
          <b-col>
            <BPaginationNavCustom
              :number-of-pages="totalPages || 1"
              :current-page="currentPage"
              :total-page="totalPages"
              :linkParams="{}"
              @page-change="fetchData()"
            >
            </BPaginationNavCustom>
          </b-col>
        </b-row>
        <!-- End of Paginate session -->
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import 'vue-cute-timeline/dist/index.css';
import { handleError } from '@/utils/common';
import { formatDate, DATE_FORMAT } from '@/utils/date';
import { showDeleteAlert } from '@/utils/sweet-alert2';
import BPaginationNavCustom from '@/view/base/bootstrap/BPaginationNavCustom.vue';
import { checkPermission } from '@/utils/saveDataToLocal';
import { PRE_ORDER_FORM_TYPE, IS_ACTIVE, PRE_ORDER_STATUS } from '@/utils/enum';
import { IS_ACTIVE_NAME } from '@/utils/enum-name';
import { VclTable } from 'vue-content-loading';

export default {
  data() {
    return {
      PRE_ORDER_STATUS,
      preOrderFormType: [
        {
          id: PRE_ORDER_FORM_TYPE.PRE_ORDER,
          name: 'Đơn đặt trước',
        },
        {
          id: PRE_ORDER_FORM_TYPE.RECEIVE_INFORMATION,
          name: 'Đơn đăng kí nhận thông tin',
        },
      ],
      linkParams: {},
      DATE_FORMAT: DATE_FORMAT,
      onLoading: false,
      listPreOrderForm: [],
      fields: [
        {
          key: 'id',
          label: 'ID',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '4%',
            textAlign: 'center',
          },
          tdClass: 'text-center',
        },
        {
          key: 'name',
          label: 'Tên chính sách',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'typeName',
          label: 'Loại',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'updatedByName',
          label: 'Người cập nhật',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-left',
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
          thStyle: { width: '10%' },
        },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      totalPages: 1,
      totalItems: 0,
      params: {
        page: 1,
        limit: 10,
        formName: '',
        type: null,
      },
      currentPage: 1,
      limit: 10,
    };
  },
  computed: {
    preOrderFormComputed() {
      return this.listPreOrderForm;
    },
    startIndex() {
      return (this.currentPage - 1) * this.limit + 1;
    },
    endIndex() {
      return this.currentPage * this.limit;
    },
  },
  methods: {
    showDeleteAlert,
    formatDate,
    checkPermission,
    getTypeName(type) {
      if (type === PRE_ORDER_FORM_TYPE.PRE_ORDER) {
        return 'Đặt trước';
      }
      if (type === PRE_ORDER_FORM_TYPE.RECEIVE_INFORMATION) {
        return 'Nhận thông tin';
      }
    },
    getStatusName(status) {
      return IS_ACTIVE_NAME[status] || '';
    },
    statusNameClasses(status) {
      const statusClassMapping = {
        [IS_ACTIVE.TRUE]: 'label-light-success',
        [IS_ACTIVE.FALSE]: 'label-light-danger',
      };
      return statusClassMapping[status] || 'label-light-dark';
    },
    removeHighlightRow(id) {
      const rows = document.querySelectorAll(`.tr-${id}`);
      if (rows.length) {
        rows.forEach((item) => {
          item.classList.remove('table-hover');
        });
      }
    },
    highlightRow(id) {
      const rows = document.querySelectorAll(`.tr-${id}`);
      if (rows.length) {
        rows.forEach((item) => {
          item.classList.add('table-hover');
        });
      }
    },
    redirectToCreatePage() {
      this.navigateToUpsertPreOrderFormPage({ action: 'create' });
    },
    redirectToPreOrderPage(query = {}) {
      this.$router.push({
        name: 'list-pre-order',
        query,
      });
    },
    navigateToUpsertPreOrderFormPage(query = {}) {
      this.$router.push({
        name: 'upsert-pre-order-form',
        query,
      });
    },
    editItem(item) {
      this.navigateToUpsertPreOrderFormPage({
        action: 'update',
        id: item.id,
      });
    },
    viewDetail(item) {
      this.navigateToUpsertPreOrderFormPage({
        action: 'view',
        id: item.id,
      });
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'pre-order-forms',
      });
      this.fetchData();
    },
    setParamFilter() {
      this.currentPage = this.$route.query.page || 1;
      return {
        ...this.params,
        page: this.currentPage,
        limit: this.limit,
        name: this.params.formName,
      };
    },
    fetchData: async function () {
      try {
        if (this.onLoading) return;
        this.onLoading = true;
        const params = this.setParamFilter();
        const {
          data: { data },
        } = await ApiService.query('pre-order-form', {
          params,
        });
        this.listPreOrderForm = data.result;
        this.totalItems = data.totalItems;
        this.totalPages = data.totalPages;
      } catch (error) {
        handleError(error);
      } finally {
        this.onLoading = false;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Form đặt trước', route: 'pre-order-forms' },
      { title: 'Danh sách form đặt trước' },
    ]);
  },
  created() {
    this.fetchData();
  },
  components: {
    KTCodePreview,
    VclTable,
    BPaginationNavCustom,
  },
};
</script>

<style scoped>
.btn-size {
  width: 90px;
}

.icon-size {
  font-size: 1rem;
}

.nav .nav-link {
  color: #3f4254;
  font-weight: 800;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}

input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

code,
pre {
  margin: 1em 0;
  padding: 1em;
  border: 1px solid #bbb;
  display: block;
  background: #ddd;
  border-radius: 3px;
}

.form-group {
  margin-bottom: 1em;
}

.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: auto;
}

.icon:hover {
  background-color: #90c6fc;
}
.table-hover {
  background-color: #a8bbbf33;
}
</style>
<style lang="scss" scoped>
.list-warranty-benefit-policy-page {
  .dropdown-item-text {
    font-size: 12px;
    color: #3f4254;
  }
}
.pre-order-hover:hover {
  text-decoration: underline;
  opacity: 0.7;
}
</style>
